@use "sass:math" as math;
//  Font families

$font-family__sans-serif: "Montserrat", Helvetica, Arial, sans-serif;

$font-family__base: $font-family__sans-serif;

//  Headings
$heading__font-family__base: "ClashDisplay", "Montserrat", Helvetica, Arial, sans-serif;
$h1__font-family: $heading__font-family__base;
$h2__font-family: $heading__font-family__base;
$h3__font-family: $heading__font-family__base;
$h4__font-family: $font-family__sans-serif;
$h5__font-family: $font-family__sans-serif;
$h6__font-family: $font-family__sans-serif;

//  Sizes
$root__font-size: 62.5%; // Defines ratio between root font size and base font size, 1rem = 10px
$font-size-ratio__base: 1.5; // Defines ratio of the root font-size to the base font-size

$font-size-unit: rem; // The unit to which most typography values will be converted by default
$font-size-unit-ratio: math.div($root__font-size * 15 * 0.01, 1%); // Ratio of the root font-size to the font-size unit
$font-size-unit-convert: true; // Controls whether font-size values are converted to the specified font-size unit

$font-size__base: $font-size-unit-ratio * $font-size-ratio__base * 1px; // Base font size value in <b>px</b>
$font-size__body: floor(1.125 * $font-size__base); // 18px

$font-size__xl: ceil(3.75 * $font-size__base); // 60px
$font-size__l: ceil(2.5 * $font-size__base); // 40px
$font-size__m: ceil(1.875 * $font-size__base); // 30px
$font-size__s: ceil(1.25 * $font-size__base); // 20px
$font-size__xs: floor(0.75 * $font-size__base); // 12px

//  Weights
$font-weight__thin: 100;
$font-weight__extralight: 200;
$font-weight__light: 300;
$font-weight__medium: 500;
$font-weight__demibold: 600;
$font-weight__bold: 700;
$font-weight__extrabold: 800;
$font-weight__heavy: 900;

//  Line heights
$line-height__base: 1.25;
$line-height__computed: floor($font-size__base * $line-height__base);
$line-height__xl: 1.7;
$line-height__l: 1.5;
$line-height__s: 1.33;

//  Colors
$text__color: $primary__color;

//
//  Indents
//  ---------------------------------------------

$indent__base: $line-height__computed; // 20px
$indent__xl: $indent__base * 2; // 40px
$indent__l: $indent__base * 1.5; // 30px
$indent__m: $indent__base * 1.25; // 25px
$indent__s: $indent__base * 0.5; // 10px
$indent__xs: $indent__base * 0.25; // 5px
